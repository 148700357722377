.App {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#headline {
  animation-name: floatIn;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  backface-visibility : hidden;
}

#headline h1 {
  font-size: 88px;
  font-weight: normal;
  margin: 0;
}

#about {
  font-size: 16px;
  font-weight: 400;
  animation-name: floatIn;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-delay: 0.125s;
  animation-fill-mode: both;
  backface-visibility : hidden;
}

#contact {
  animation-name: floatIn;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-delay: 0.25s;
  animation-fill-mode: both;
  backface-visibility : hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes floatIn {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@media (max-width: 600px) {
  #headline h1 {
    font-size: 60px;
  }
}

@media (max-width: 400px) {
  #headline h1 {
    font-size: 40px;
  }
}
